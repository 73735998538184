import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormData,
    objectToFormData,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
        total: 0,
    },
    list: [],
    active: null,
    reportDoc: null,
    documents: [],
};

export const EducationalCredits = createSlice({
    name: "EducationalCredits",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        setReportDoc: (state, action) => {
            state.reportDoc = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        unsetActive: (state) => {
            state.active = initialState.active;
        },
        setDocuments: (state, action) => {
            state.documents = action.payload;
        },
        unsetDocuments: (state) => {
            state.documents = initialState.documents;
        },
    },
});

export const {
    setPagination,
    setList,
    setReportDoc,
    clearPagination,
    setActive,
    unsetActive,
    setDocuments,
    unsetDocuments,
} = EducationalCredits.actions;

export default EducationalCredits.reducer;

// Actions
export const startGetEducationalCredits = ({
    page = 1,
    query = "",
    number_rows = 10,
    asList = 0,
    cycles,
    coordination_ids,
    curriculum_ids,
    status,
}) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        if (asList) {
            params.set("asList", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
        }

        if (Object?.keys(cycles)?.length > 0) {
            params.set("cycles", `${cycles?.year}-${cycles?.sub_cycle}`);
        }

        if (coordination_ids?.length) {
            params.set(
                "coordination_ids",
                coordination_ids.map((item) => item.value).join(",")
            );
        }

        if (curriculum_ids?.length) {
            params.set(
                "curriculum_ids",
                curriculum_ids.map((item) => item.value).join(",")
            );
        }

        if (status?.length) {
            params.set("status", status.map((item) => item.value).join(","));
        }

        const resp = await Call(
            `operators/educational-credits/show`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success));
            } else {
                dispatch(setPagination(resp.success));
            }
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startGetById = (educational_credit_id, navigate = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/educational-credits/get/${educational_credit_id}`,
            "GET"
        );

        if (resp.success) {
            dispatch(setActive(resp.success));
        } else {
            navigate("/app/educational-credits");
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startCreateRequest = (values, setErrors, callback = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/educational-credits/create-request`,
            "POST",
            values
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Solicitud de crédito educativo registrada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const getReportDoc = (educational_credit_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/educational-credits/doc-data/${educational_credit_id}`,
            "GET"
        );

        if (resp.success) {
            dispatch(setReportDoc(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startGetDocumentsByEducationalCredit = (educational_credit_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/educational-credits/documents/get/${educational_credit_id}`,
            "GET"
        );

        if (resp.success) {
            dispatch(setDocuments(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startUploadDocument = (values, setErrors, callback = () => {}) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "Confirmar acción",
            icon: "info",
            text: "Confirmo que el documento que estoy a punto de subir es correcto y cuenta con una calidad alta y legible.",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sí, confirmo",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            width: 450,
        });

        if (result?.isConfirmed) {
            dispatch(startUILoading());

            const resp = await CallWithFormData(
                `operators/educational-credits/documents/upload/${values.educational_credit_document_id}`,
                "POST",
                objectToFormData(values)
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Se cargó el documento.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                callback();
            } else {
                errorHandler(resp, setErrors);
            }

            dispatch(stopUILoading());
        }
    };
};

export const startDeleteDocument = (
    educational_credit_document_id,
    setErrors,
    callback = () => {}
) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estás seguro de eliminar?",
            icon: "warning",
            text: "¡No podrás revertir esta acción!",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sí, eliminar",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            input: "text",
            inputLabel:
                "Escribe la palabra 'eliminar' para confirmar esta operación.",
            inputAttributes: {
                autocapitalize: "off",
            },
        });

        if (result.isConfirmed && result.value == "eliminar") {
            dispatch(startUILoading());

            const resp = await Call(
                `operators/educational-credits/documents/delete/${educational_credit_document_id}`,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Se eliminó el documento.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                callback();
            } else {
                errorHandler(resp, setErrors);
            }

            dispatch(stopUILoading());
        }
    };
};
